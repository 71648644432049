// 严凯 V2.2.0
<template>
  <div ref="parent" class="page-add">
    <a-form ref="formRef" :rules="rules" :model="modalVal" class="form-add">
      <a-form-item label="流程名称" name="processName" class="form-item">
        <a-input
          :maxlength="30"
          v-model:value="modalVal.processName"
          placeholder="请输入流程名称"
          class="form-item-width"
          :disabled="isApprover"
        />
      </a-form-item>
      <a-form-item label="适用范围" name="department" class="form-item" v-if="modalVal.rangeValue.length">
        <div :class="{ 'range-form-item': true, 'range-form-item-edit': true }">
          <span v-if="!modalVal.rangeOptions?.length" class="range-form-empty"
            >请选择部门或人员（默认全体人员可见）</span
          >
          <template v-else>
            <span class="range-span" v-for="item in modalVal.rangeOptions" :key="item.key">{{ item.label }}</span>
          </template>
        </div>
      </a-form-item>
      <a-form-item label="流程类型" name="process" class="form-item">
        <a-radio-group v-model:value="modalVal.process" @change="handleProcessTypeChange" class="form-item-width">
          <a-radio :value="isApprover ? 3 : 1">{{ isApprover ? '补盖流程' : '固定流程' }} </a-radio>
          <!-- <a-radio :value="2"> 自由流程 </a-radio> -->
        </a-radio-group>
      </a-form-item>
      <a-form-item
        v-if="modalVal.process === 1 || modalVal.process === 3"
        label="审批流程"
        name="staffList"
        :class="modalVal.staffList.length ? 'form-item form-item-error' : 'form-item'"
      >
        <StaffProcess
          @open-approval-modal="openApprovalModal"
          @delete-approval="deleteApprovalFn"
          :approvalList="modalVal.staffList"
          :isAdd="true"
          :isEdit="isEdit"
          :isApprover="isApprover"
          class="form-item-width"
        />
      </a-form-item>
      <section v-else class="free-tips">
        <!-- <p class="title">
          <svg>
            <use xlink:href="#iconWaring"></use></svg
          >温馨提示
        </p> -->
        <p class="text">
          自由流程允许人员在提交用印申请时自行选择审批人，请知晓此流程可能带来的风险，并规定好适用范围。
        </p>
      </section>

      <!-- 抄送人 -->
      <a-form-item label="抄送人" name="informPeople" class="form-item" v-if="!isApprover">
        <div class="form-tips-view">
          <div
            @click="handleDepartment(3)"
            class="printer"
            :style="{
              padding: modalVal.informPeople.length ? '6px 24px 1px 10px' : '0 0 0 12px',
              'line-height': modalVal.informPeople.length ? 'auto' : '32px',
              maxHeight: '100px',
              overflow: 'auto'
            }"
          >
            <template v-if="modalVal.informPeople.length">
              <div v-for="(item, index) in modalVal.informPeople" :key="item.dataId" class="pt-tag">
                <span class="pt-name" :title="item.dataName">{{ item.dataName }}</span>
                <div @click.stop="deleteSta(index, 3)" class="pt-close">
                  <CloseOutlined />
                </div>
              </div>
            </template>
            <span v-else>请选择抄送人</span>
            <DownOutlined class="down-arrow" />
          </div>
        </div>
      </a-form-item>

      <a-form-item label="" class="form-item">
        <section style="margin-left: 82px">
          <a-button class="cancel-btn" @click="handleCancel">取消</a-button>
          <a-button class="submit-btn" @click="comfirmAdd" :loading="loading" v-if="isEdit">确定</a-button>
        </section>
      </a-form-item>
    </a-form>
    <a-modal
      :width="655"
      centered
      title="选择审批人"
      @cancel="closeApprovaModel"
      @ok="submit"
      :visible="approvalVisible"
      :getContainer="() => $refs.parent"
    >
      <a-radio-group
        v-model:value="approvalType"
        :default-value="1"
        @change="radioChange"
        style="margin-bottom:20px"
        v-if="!isApprover"
      >
        <a-radio :value="1">指定人员</a-radio>
        <a-radio :value="2" v-if="!isHavLeader">部门主管</a-radio>
      </a-radio-group>
      <a-row class="approval-modal" v-if="approvalType == 1">
        <a-col :span="12" style="padding: 16px 0 16px 14px; border-right: 1px solid rgba(0, 0, 0, 0.06)">
          <section style="margin-bottom: 24px" v-if="check.totalSearchList.length - modalVal.staffList.length">
            <a-input
              class="search-input"
              style="width: 190px; margin-right: 17px"
              :maxlength="10"
              v-model:value="check.searchLbwVal"
              placeholder="请输入姓名查询"
            >
              <template #prefix> <img src="@/assets/svg/search.svg" /> </template>
            </a-input>
            <a-button type="primary" @click="searchApprovalList" style="border-radius: 2px">查找</a-button>
          </section>
          <div style="overflow-y: scroll" v-show="check.searchLbwList.length">
            <!-- <a-checkbox :indeterminate="check.indeterminate" :checked="check.checkAll" @change="onCheckAllChange">
              全选
            </a-checkbox> -->
            <a-checkbox-group :value="check.checkedList">
              <Virtual :list="check.searchLbwList" :size="62" :remain="8" :isScrollTop="isVirtualListScroll">
                <template #default="{ item, index }">
                  <StaffItem :item="item" :index="index" :onChange="onChange" />
                </template>
              </Virtual>
            </a-checkbox-group>
          </div>
          <div class="no-data" v-show="!check.searchLbwList.length">
            <a-empty :image="simpleImage" class="no-icon" />
          </div>
        </a-col>
        <a-col :span="12" style="padding: 16px 0 16px 14px">
          <p style="color: rgba(0, 0, 0, 0.85); margin-bottom: 24px; font-size: 14px">以下人员拥有该审批的管理权限</p>
          <div class="scroll">
            <StaffList :list="check.checkedListRight" :onChangeList="onChangeList" />
          </div>
        </a-col>
      </a-row>
      <div v-else style="display:flex">
        <div style="margin-right:10px">找不到部门主管时，由上级部门主管代审批</div>
        <a-switch checked-children="启" un-checked-children="禁" v-model:checked="switchValue" @change="switchChange" />
      </div>
    </a-modal>

    <!-- 抄送人弹框 -->
    <RangeModal
      :selectData="departmentList"
      :visible="RangeModalVisible"
      :title="popText"
      @close="handledRangeVisible"
      @checkData="checkData"
      ref="sonRef"
      :type="type"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from 'vue'
import {
  getProcessStaffList,
  validateProcessName,
  getDepartmentOrganizational,
  addProcess,
  getDepartmentShow,
  getApplyProcessStaff,
  editProcess
} from '@/apis/businessManage'
import VirtualList from '@/components/VirtualList'
import Virtual from '@/components/VirtualList/virtualList'
// import ApprovalStaffItem from '@/components/VirtualList/approvalStaffItem'
import StaffItem from './components/staffItem'
import StaffList from './components/staffList'
import StaffProcess from './components/staffProcess'
import { useCheckStateAndEvent } from '@/utils/hooks'
import ApprovalStaffList from '@/views/components/approvalStaffList'
import { DownOutlined, CloseOutlined } from '@ant-design/icons-vue'
import '@/style/page-add.scss'
import { useRouter, useRoute } from 'vue-router'
import { cmsNotice } from '@/utils/utils'
import { useStore } from 'vuex'
import { SET_APPLY_PROCESS_LIST } from '@/store/globalData/mutations-type'
import { Empty } from 'ant-design-vue'
import RangeBox from './components/rangeBox.vue'
import RangeModal from '@/components/rangeModal'
import { getUserInfo } from '@/apis/user'
export default defineComponent({
  components: {
    VirtualList,
    // ApprovalStaffItem,
    StaffItem,
    ApprovalStaffList,
    StaffList,
    StaffProcess,
    Virtual,
    Empty,
    RangeBox,
    RangeModal,
    DownOutlined,
    CloseOutlined
  },
  setup(props, ctx) {
    const isEdit = ref(true)
    const isApprover = ref(true)
    const router = useRouter()
    const formRef = ref()
    const store = useStore()
    const route = useRoute()
    const modalVal = reactive({
      processName: undefined,
      // business: 1, // 业务类型
      process: 1, // 流程类型
      department: undefined, // 适用范围
      departmentVal: {
        // 适用范围的选择值
        staff: [],
        department: []
      },
      rangeValue: [],
      rangeOptions: [],
      staffList: [],
      informPeople: [] // 抄送人
    })
    const approvalFormRef = ref()
    const approvalModel = reactive({
      approvalName: undefined
    })
    const approvalRules = {
      approvalName: [
        {
          type: 'number',
          required: true,
          message: '请选择审批人'
        }
      ]
    }
    const state = reactive({
      expandedKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      approvalVisible: false,
      isSelectopen: false, // 是否打开流程范围的下拉框
      isFocus: true, // 是否流程范围获取焦点
      isClickTab: true, // 是否点的时流程范围的下拉框上
      scopeVisible: true, // 流程范围下拉框的数据刷新的作用
      organizationalList: [],
      isAllFlowScope: false,
      isVirtualListScroll: 0,
      isVirtualListScroll2: 0,
      loading: false,
      approvalType: 1, //审批人类型
      switchValue: false,
      isHavLeader: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      popText: '', //弹框文字
      departmentList: [], //使用范围列表
      RangeModalVisible: false, // 弹框开关
      type: undefined // 抄送人类型
    })
    const rules = ref()
    rules.value = {
      processName: [
        {
          required: true,
          message: '请输入流程名称'
        }
      ],
      process: [
        {
          type: 'number',
          required: true,
          message: '请选择流程类型'
        }
      ],
      staffList: [
        {
          type: 'array',
          required: true,
          message: '请选择审批流程'
        }
      ]
    }
    const validateProcessNameFn = async () => {
      // 校验流程名字是否重复
      // if (props.types === 'edit') {
      //   return { success: true }
      // }
      const res = await validateProcessName({ flowName: modalVal.processName })
      return res
    }

    // 选择抄送人唤起弹框
    const handleDepartment = async type => {
      state.type = type
      state.RangeModalVisible = true
      if (type === 3) {
        console.log('弹框数据', modalVal.informPeople)
        state.departmentList = modalVal.informPeople
        state.popText = '选择抄送人'
      }
    }
    // 删除其中一项
    const deleteSta = (index, type) => {
      if (type === 3) {
        modalVal.informPeople.splice(index, 1)
      }
    }
    // 抄送人点确定
    const checkData = e => {
      console.log('modalVal.ScopeApplication', e)
      if (state.type === 3) {
        modalVal.informPeople = e
      }
    }
    // 抄送人弹框关闭
    const handledRangeVisible = e => {
      state.RangeModalVisible = e
    }

    const enhancerMapFn = {
      closeApprovaModel() {
        state.approvalVisible = false
      },
      selectApprovalFn() {
        state.approvalVisible = false
        setTimeout(() => {
          formRef.value.clearValidate('staffList')
          state.isVirtualListScroll2 += '1'
        })
      },
      deleteApprovalFn() {
        state.isVirtualListScroll2 = {}
      },
      searchApprovalList() {
        typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
      },
      openApprovalModal() {
        state.approvalVisible = true
        state.approvalType = 1
        state.switchValue = false
        // console.log(modalVal.staffList)
        if (modalVal.staffList.length > 0) {
          let tmp = modalVal.staffList.filter(val => val.type == 2)
          reloadSearchLbwList()
          //  console.log(tmp)
          if (tmp.length == 0) {
            state.isHavLeader = false
          } else {
            state.isHavLeader = true
          }
        } else {
          state.isHavLeader = false
        }
        //  console.log(state.isHavLeader)
        setTimeout(() => {
          typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
        })
      },
      changeStaffList() {
        modalVal.staffList = check.staffList.map(item => item)
      }
    }
    const {
      check,
      onChange,
      onCheckAllChange,
      reloadSearchLbwList,
      selectApprovalFn,
      searchApprovalList,
      deleteApprovalFn,
      closeApprovaModel,
      openApprovalModal,
      initialApproalList,
      onChangeList
    } = useCheckStateAndEvent(enhancerMapFn)

    // 提交函数
    const comfirmAdd = () => {
      // emit('update:loading', true)
      console.log('抄送人数据', modalVal.informPeople)
      state.loading = true
      formRef.value
        .validate()
        .then(async () => {
          const params = {}
          // const res = await validateProcessNameFn()
          // if (!res.success) {
          //   state.loading = false
          //   return
          // }
          params.flowId = modalVal.flowId
          params.flowName = modalVal.processName
          params.flowType = modalVal.process
          params.businessType = modalVal.business
          if (modalVal.process === 1 || modalVal.process === 3) {
            //流程类型
            // console.log(modalVal.staffList,"参数");
            params.flowUserRelsDTO = modalVal.staffList.map(item => {
              //审批流程人员
              if (item.staffId == -1 || item.staffId == null) {
                item.staffId = null
              }
              let tmp = {
                staffId: item.staffId == null ? null : item.id || item.staffId,
                type: item.staffId == null ? 2 : 1,
                upDpLook: item.upDpLook ? item.upDpLook : null
              }
              return tmp
            })
          }

          // 抄送人数据
          if (modalVal.process === 1) {
            let flowUserRelCCList = modalVal.informPeople.map(item => {
              return {
                staffId: item.dataId
              }
            })
            params.flowUserRelCCList = flowUserRelCCList
          }

          //指定人员/部门主管
          let tmp = []
          if (modalVal.process === 1) tmp = params.flowUserRelsDTO.filter(val => val.staffId == null)
          // console.log(tmp);
          if (tmp.length > 0) {
            params.flowSupervisor = tmp[0].upDpLook ? 3 : 2
          } else {
            params.flowSupervisor = 1
          }
          // v2.5.7 去掉适用范围，默认传1
          params.flowScope = 1 //flowScope1、默认公司全部员工 2、自选部门或者员工
          // if ((check.totalSearchList.length==modalVal.departmentVal.staff.length)||(!modalVal.departmentVal.staff.length && !modalVal.departmentVal.department.length)) {
          //     params.flowScope = 1
          //     params.flowScopeVO = []
          // } else {
          //   iterationProcessRange(params, modalVal)
          // }
          modalSubmit(params)
        })
        .catch(() => (state.loading = false))

      function iterationProcessRange(params, modalVal) {
        // 处理 选择流程范围把值提取出来的函数
        params.flowScope = 2
        params.flowScopeDTO = []
        modalVal.departmentVal.department.length &&
          (modalVal.departmentVal.department = modalVal.departmentVal.department.filter(
            item => typeof item === 'number'
          ))
        modalVal.departmentVal.staff.length > modalVal.departmentVal.department.length
          ? modalVal.departmentVal.staff.forEach((item, index) => {
              // console.log(item, 'item')
              params.flowScopeDTO[index] = {
                staffId: item,
                departmentId: modalVal.departmentVal.department[index]
              }
            })
          : modalVal.departmentVal.department.forEach(
              (item, index) =>
                (params.flowScopeDTO[index] = { departmentId: item, staffId: modalVal.departmentVal.staff[index] })
            )
        !params.flowScopeDTO.length ? (params.flowScope = 1) : null
      }
    }
    const getProcessStaff = async () => {
      // 获取流程的审批人
      const res = await getProcessStaffList()
      check.searchLbwList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
      check.totalSearchList = res.data.map(item => ({ ...item, label: item.name, value: item.id }))
    }
    const getOrganizationalList = async params => {
      // 获取流程范围中部门列表的函数
      const res = await getDepartmentShow(params)
      const permissionList = res.data
      state.organizationalList = permissionList.length ? permissionList : []
    }
    const onExpand = expandedKeys => {
      // 部门列表的父级展开
      state.expandedKeys = expandedKeys
      state.autoExpandParent = false
    }
    const onCheck = checkedKeys => {
      // 部门列表的选择回调
      modalVal.power = checkedKeys
    }
    const selectScopeFocus = () => {
      // 处理流程范围获取焦点函数
      state.isSelectopen = true
      state.isClickTab = true
      setTimeout(() => {
        state.isFocus = false
        state.isClickTab = false
      }, 100)
    }
    const selectScopeBlur = () => {
      // 处理流程范围失去焦点函数
      setTimeout(() => {
        state.isFocus = true
      }, 20)
    }
    const selectScopeClick = () => {
      // 处理流程范围下拉框的点击
      !state.isFocus && (state.isSelectopen = !state.isSelectopen)
      state.isClickTab = true
      setTimeout(() => {
        state.isClickTab = false
      }, 80)
    }
    const clickSelectTab = () => {
      // 处理流程范围下拉框的点击
      state.isClickTab = true
      state.isFocus = true
      setTimeout(() => {
        state.isClickTab = false
      }, 100)
    }
    const clickNoTab = () => {
      // 处理点击非流程范围下拉框区域
      if (!state.isClickTab) {
        state.isSelectopen = false
        state.isClickTab = true
      }
    }

    const handleCancel = () => {
      // 取消流程弹窗
      // formRef.value.resetFields()
      // emit('update:visible', false)
      router.back()
    }
    const filterSelectProcessRange = (processType, processRangeVal) => {
      // 处理下修改时流程，赋值流程范围
      modalVal.departmentVal = {
        staff: [],
        department: []
      }
      if (processType === 2) {
        processRangeVal.forEach(item => {
          item.staffId && modalVal.departmentVal.staff.push(item.staffId)
          item.departmentId && modalVal.departmentVal.department.push(item.departmentId)
        })
      } else {
        modalVal.departmentVal.department.push('sss')
        state.isAllFlowScope = true
      }
    }

    const handleProcessTypeChange = () => {
      if (modalVal.process === 1) {
        modalVal.staffList = []
        initialApproalList()
      }
    }
    const modalSubmit = async value => {
      console.log('提交的值', value)
      const res = await editProcess(JSON.stringify({ ...value }))
      const localData = JSON.parse(JSON.stringify({ ...value }))
      if (res.success) {
        store.dispatch(`globalData/${SET_APPLY_PROCESS_LIST}`)
        cmsNotice('success', '修改成功')
        localStorage.setItem('processCurrent', JSON.stringify(localData))
        router.back()
      }

      state.loading = false
    }

    const searchDepartment = value => {
      getOrganizationalList({ searchDept: value })
    }

    const radioChange = e => {
      state.approvalType = e.target.value
      state.switchValue = false
    }
    const switchChange = checked => {
      state.switchValue = checked
    }

    const submit = () => {
      if (state.approvalType == 1) {
        selectApprovalFn()
      } else {
        state.approvalVisible = false
        check.staffList.push({
          type: 2,
          staffId: null,
          id: null,
          avatarId: null,
          staffName: '部门主管',
          name: '部门主管',
          upDpLook: state.switchValue
        })
        enhancerMapFn.changeStaffList()
      }
    }
    const dataInit = () => {
      const record = JSON.parse(localStorage.getItem('processCurrent'))
      console.log('processCurrent', record)
      // let newCheckStaffList = []
      // 抄送人回显
      //       dataId: 17475
      // dataName: "慢舞"
      // dataType: 1
      // icon: "https://uatapi.libawall.com/storage/6065"
      modalVal.informPeople = record.flowUserRelCCList?.map(item => {
        return {
          dataId: item.staffId,
          dataName: item.staffName,
          dataType: 1,
          icon: item.fullAvatar ?? undefined
        }
      })
      modalVal.informPeople
      console.log('回显数据', modalVal.informPeople)
      state.isAllFlowScope = false
      modalVal.flowId = record.flowId
      modalVal.processName = record.flowName
      modalVal.business = record.businessType
      modalVal.process = record.flowType //流程类型
      modalVal.department = '已选择'
      isApprover.value = record.flowType === 3 //判断是否为补盖审批流程
      // record.flowScopeVO && record.flowScopeVO.forEach(item => {
      //   if (item.departmentId) {
      //     modalVal.rangeValue.push(item.departmentId)
      //     modalVal.rangeOptions.push({ value: item.departmentId, label: item.departmentName, disabled: true })
      //   }
      //   if (item.staffId) {
      //     modalVal.rangeValue.push(item.staffId)
      //     modalVal.rangeOptions.push({ value: item.staffId, label: item.staffName, disabled: true })
      //   }
      // })
      modalVal.flowSupervisor = record.flowSupervisor
      // filterSelectProcessRange(record.flowScope, record.flowScopeVO)
      modalVal.staffList =
        record.flowUserRelsVO && record.flowUserRelsVO.length
          ? record.flowUserRelsVO.map(item => {
              if (item.type == 2) {
                item.staffId = -1
                item.staffName = '部门主管'
                if (record.flowSupervisor == 3) {
                  // 无当前部门主管可以寻找上级
                  item.upDpLook = true
                } else {
                  item.upDpLook = false
                }
              }
              item.id = item.staffId
              item.name = item.staffName
              return item
            })
          : []
      let newCheckStaffList = modalVal.staffList.map(item => item)
      state.expandedKeys = []
      state.autoExpandParent = false
      initialApproalList(newCheckStaffList)
      typeof state.isVirtualListScroll2 === 'number' ? state.isVirtualListScroll2++ : (state.isVirtualListScroll2 = 0)
    }
    onMounted(() => {
      document.body.addEventListener('click', clickNoTab, false)
      getProcessStaff()
      getOrganizationalList()
      dataInit()
      userInfo()
    })

    onBeforeUnmount(() => {
      document.body.removeEventListener('click', clickNoTab)
    })
    //判断是否为超管
    const userInfo = async () => {
      const res = await getUserInfo()
      if (res.code === 0) {
        if (isApprover.value) isEdit.value = res.data.identity === 1
      }
      console.log('用户信息', res)
    }
    watch(
      () => state.approvalType,
      newValue => {
        if (newValue == 2) {
          check.checkedList = []
          check.checkedListRight = []
          reloadSearchLbwList()
        }
      }
    )
    return {
      comfirmAdd,
      formRef,
      modalVal,
      rules,
      onExpand,
      ...toRefs(state),
      onCheck,
      approvalFormRef,
      approvalModel,
      approvalRules,
      selectScopeFocus,
      selectScopeClick,
      selectScopeBlur,
      clickSelectTab,
      selectApprovalFn,
      closeApprovaModel,
      onCheckAllChange,
      onChange,
      check,
      deleteApprovalFn,
      handleCancel,
      searchApprovalList,
      openApprovalModal,
      handleProcessTypeChange,
      modalSubmit,
      onChangeList,
      searchDepartment,
      radioChange,
      switchChange,
      submit,
      handleDepartment,
      deleteSta,
      checkData,
      handledRangeVisible,
      isApprover,
      userInfo,
      isEdit
    }
  }
})
</script>

<style lang="scss" scoped>
// 为了解决 修改审批流程页面刷新后新增审批流程人员列表样式问题
::deep .ant-checkbox-group {
  display: inherit !important;
}
:deep(.ant-form-item-label) {
  width: 82px;
}
:deep(.ant-select) {
  .ant-select-selection-item-remove {
    display: none;
  }
}
.free-tips {
  margin-left: 82px;
  .text {
    width: 468px;
    height: 34px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    margin: -8px 0 24px 0;
  }
}
:deep(.ant-modal-header) {
  border-bottom: 0;
  padding-bottom: 0;
}
.approval-modal {
  // margin-top: -24px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
:deep(.ant-timeline-item-content) {
  top: 0;
}
.scroll {
  overflow-y: scroll;
  height: 376px;
  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    // background: #f5f5f5;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #dddddd;
    border-radius: 4px;
  }
}
.no-data {
  height: 355px;
  .no-icon {
    height: 355px;
    transform: translateY(36%);
  }
}

.form-item-error {
  :deep(.has-error .ant-form-explain) {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.range-form-empty {
  color: #bfbfbf;
  flex: 1;
  line-height: 22px;
  padding-left: 7px;
}
.range-form-item {
  width: 468px;
  min-height: 32px;
  max-height: 100px;
  overflow-y: auto;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
  cursor: pointer;
  &:hover {
    border-color: #cf3838;
  }

  .range-span {
    position: relative;
    display: flex;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    height: 24px;
    margin-top: 2px;
    margin-right: 4px;
    margin-bottom: 2px;
    padding: 0 8px 0 8px;
    line-height: 22px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 1px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &-edit {
    background: #f5f5f5;
    color: #bfbfbf;
    cursor: unset;
    &:hover {
      border-color: #d9d9d9;
    }
    .range-span {
      border-color: #d9d9d9;
    }
  }
}
</style>
<style lang="scss" scoped>
.range-box-pop {
  padding-top: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 0;
    width: 468px;
  }
}

// 抄送人
.form-tips-view {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.printer {
  width: 468px;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
  line-height: 32px;
  color: #bfbfbf;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  margin-right: 8px;
  padding: 6px 24px 1px 10px;
  transition: border-color 0.3s;
  &::-webkit-scrollbar {
    display: block;
  }
  &:hover {
    border-color: #cf3838;
  }
  .pt-tag {
    // width: 90px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    padding: 0 5px 0 7px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .pt-name {
    color: rgba(0, 0, 0, 0.85);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 7px;
    white-space: nowrap;
    display: inline-block;
    flex: 1;
    cursor: default;
  }
  .pt-close {
    width: 14px;
    height: 14px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #333;
    }
  }
  .down-arrow {
    position: absolute;
    font-size: 12px;
    top: 10px;
    right: 11px;
    color: rgba(0, 0, 0, 0.3);
  }
}
</style>
